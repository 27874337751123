import { useTranslation } from 'next-i18next'

import { routes } from 'routes'
import LinkAnchor from 'components/LinkAnchor'

export default function ForgotPasswordLink() {
  const { t } = useTranslation()
  return (
    <LinkAnchor
      size="small"
      alignSelf="center"
      weight="normal"
      href={routes.forgotPassword}
      label={t('cta.forgot-my-password')}
    />
  )
}
