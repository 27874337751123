/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { Box, FormField, FormFieldExtendedProps, TextInput } from 'grommet'

import ForgotPasswordLink from 'components/auth/ForgotPasswordLink'

import FormSection from '../FormSection'

type Props = {
  disabled?: boolean
  forgotPassword?: boolean
} & FormFieldExtendedProps
export default function PasswordField({
  name = 'password',
  forgotPassword = false,
  disabled,
  ...props
}: Props) {
  const { t } = useTranslation()
  return (
    <FormSection>
      <FormField
        required
        margin="none"
        name={name}
        htmlFor={name}
        label={t('basic.password')}
        {...props}>
        <TextInput id={name} name={name} type="password" disabled={disabled} />
      </FormField>
      {forgotPassword && (
        <Box direction="row" justify="end" margin={{ top: 'xsmall', right: 'xsmall' }}>
          <ForgotPasswordLink />
        </Box>
      )}
    </FormSection>
  )
}
