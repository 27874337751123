/** @jsxImportSource @emotion/react */
import { Form } from 'grommet'
import { useState } from 'react'
import { useSignIn } from 'react-supabase'
import { useTranslation } from 'react-i18next'
import { UserCredentials } from '@supabase/gotrue-js'

import { trackEvent } from 'utils/useGTM'
import FormBody from 'components/form/FormBody'
import Notification from 'components/Notification'
import FormFooter from 'components/form/FormFooter'
import FormSection from 'components/form/FormSection'
import SocialLogins from 'components/auth/SocialLogins'
import EmailField from 'components/form/fields/EmailField'
import LoadingContainer from 'components/LoadingContainer'
import SubmitFormButton from 'components/form/SubmitFormButton'
import PasswordField from 'components/form/fields/PasswordField'

import AbstractBackground from './AbstractBackground'

export default function LoginForm() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [{ error, fetching }, signIn] = useSignIn()

  const [form, setForm] = useState<UserCredentials>({
    email: '',
    password: '',
  })

  return (
    <LoadingContainer loading={loading || fetching} title={t('pages.login.title')}>
      <AbstractBackground bgImage="/evie-s-9vUg7s50CDo-unsplash.jpg">
        <Form<UserCredentials>
          name="login_form"
          className="login-form"
          messages={{ required: t('validation.required'), invalid: t('validation.invalid') }}
          value={form}
          onChange={(nextValue) => setForm(nextValue)}
          onSubmit={async ({ value }) => {
            setLoading(true)
            const { error } = await signIn(value)
            setLoading(false)
            if (error) {
              trackEvent({ event: 'ErrorLogin' })
            } else {
              trackEvent({ event: 'Login' })
            }
          }}>
          <FormBody>
            <EmailField disabled={fetching} />
            <PasswordField disabled={fetching} forgotPassword />
            {error && (
              <FormSection>
                <Notification status="error">{error?.message}</Notification>
              </FormSection>
            )}
          </FormBody>
          <FormFooter margin={{ vertical: 'small' }}>
            <SubmitFormButton size="medium" fetching={loading} label={t('cta.login')} />
          </FormFooter>
          <SocialLogins signup magicLink />
        </Form>
      </AbstractBackground>
    </LoadingContainer>
  )
}
