/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { sample } from 'lodash'
import { Box, Text } from 'grommet'
import { BlockQuote } from 'grommet-icons'

type Quote = {
  content: string
  author: string
}
const quotes: Quote[] = [
  {
    content:
      'There is no separation of mind and emotions; emotions, thinking, and learning is all linked',
    author: 'Eric Jensen',
  },
  {
    content:
      'As more and more artificial intelligence is entering into the world, more and more emotional intelligence must enter into leadership.',
    author: 'Amit Ray',
  },
  {
    content: 'The emotional brain responds to an event more quickly than the thinking brain.',
    author: 'Daniel Goleman',
  },
  {
    content:
      'We are dangerous when we are not conscious of our responsibility for how we behave, think, and feel.',
    author: 'Marshall B. Rosenberg',
  },
  {
    content:
      'When dealing with people, remember you are not dealing with creatures of logic, but with creatures of emotion.',
    author: 'Dale Carnegie',
  },
]

export default function RandomQuote() {
  const { content, author } = useMemo(() => sample(quotes) ?? quotes[0], [])
  return (
    <Box css={{ zIndex: 5 }} pad={{ left: 'large', bottom: 'large' }} justify="end" gap="medium">
      <Text size="medium">{content}</Text>
      <Box direction="row" justify="between">
        <BlockQuote color="gray" />
        <Text size="caption" textAlign="end" color="gray">
          {author}
        </Text>
      </Box>
    </Box>
  )
}
