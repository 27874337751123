/** @jsxImportSource @emotion/react */
import { Box } from 'grommet'
import { PropsWithChildren } from 'react'

import useDeviceSize from 'hooks/useDevice'
import RandomQuote from '../RandomQuote'

type Props = PropsWithChildren<{
  bgImage: string
}>

export default function AbstractBackground({ children, bgImage }: Props) {
  const { small } = useDeviceSize()
  if (small) return <>{children}</>
  return (
    <>
      <Box
        width="medium"
        align="center"
        justify="end"
        css={{
          position: 'absolute',
          width: '40%',
          top: '4rem',
          left: 0,
          bottom: 0,
        }}>
        <Box
          css={{
            position: 'absolute',
            inset: 0,
            maskImage: 'linear-gradient(to right, #000 50% , transparent)',
          }}
          background={{
            image: `url('${bgImage}')`,
            size: 'cover',
            position: 'left',
            opacity: 'medium',
          }}
        />
        &nbsp;
        <RandomQuote />
      </Box>
      <Box width="medium" pad={{ horizontal: 'large' }} margin={{ left: '45%' }}>
        {children}
      </Box>
    </>
  )
}
