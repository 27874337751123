/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next'
import { FormField, TextInput } from 'grommet'

import FormSection from '../FormSection'

type Props = {
  disabled?: boolean
}
export default function EmailField({ disabled }: Props) {
  const { t } = useTranslation()
  return (
    <FormSection>
      <FormField required margin="none" name="email" htmlFor="email" label={t('basic.email')}>
        <TextInput id="email" name="email" type="email" autoComplete="email" disabled={disabled} />
      </FormField>
    </FormSection>
  )
}
