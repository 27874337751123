import { Box } from 'grommet'
import { GetServerSideProps } from 'next'
import { useTranslation } from 'react-i18next'

import { createPage } from 'util/ssp'
import { redirectBackTo } from 'util/login'
import Layout from 'components/layout/Layout'
import LoginForm from 'components/auth/form/LoginForm'

export const getServerSideProps: GetServerSideProps = async function (ctx) {
  return await createPage(ctx, {
    authenticatedRedirect: { destination: redirectBackTo(ctx.query), permanent: false },
  })
}

export const LoginPage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Layout title={t('pages.login.title')}>
      <Box align="center" margin={{ bottom: 'large' }}>
        <LoginForm />
      </Box>
    </Layout>
  )
}

export default LoginPage
